<template>
  <div class="customer-c">
            <div class="top-box">
              <div class="top-title">用户管理</div>
              <div class="btn-box">
                <div class="btn" @click="handleSearchClick()"><i class="el-icon-search"></i> 查询</div>
                <div @click="handleAddClick()" class="btn"><i class="el-icon-plus"></i> 新建</div>
              </div>
            </div>
            <el-row class="form-group">
              <el-col :span="6" class="form-item">
                  <div class="label">账号</div>
                  <div class="input-box">
                      <el-input v-model="searchName" placeholder="请输入账号" class="form-control"></el-input>
                  </div>
              </el-col>
              <el-col :span="5" class="form-item">
                  <div class="label">电话号码</div>
                  <div class="input-box">
                      <el-input v-model="searchTel" placeholder="请输入电话号码" class="form-control"></el-input>
                  </div>
              </el-col>
              <el-col :span="5" class="form-item">
                  <div class="label">真实姓名</div>
                  <div class="input-box">
                    <el-input v-model="searchRealName" placeholder="请输入真实姓名" class="form-control"></el-input>
                  </div>
              </el-col>
            </el-row>

            <div class="table-container-body">
              <el-table :data="tableData" height="500" stripe style="width: 100%" ref="studentTable" class="table-body"
              :header-cell-style="{background:'#e6e6e6', color: '#646464'}" v-loading="tableLoading">
                <el-table-column fixed type="selection" width="42"></el-table-column>
                <el-table-column fixed prop="id" label="序号"></el-table-column>
                <el-table-column fixed prop="userName" label="账号"></el-table-column>
                <el-table-column prop="adminType" label="人员类型">
                   <template slot-scope="scope">
                     <el-tag v-if="scope.row.adminType == 0" type="warning">管理员</el-tag>
                     <el-tag v-else-if="scope.row.adminType == 2" type="danger">超级管理员</el-tag>
                     <el-tag v-else type="info">非管理员</el-tag>
                   </template>
                </el-table-column>
                <el-table-column prop="fullname" label="真实姓名"></el-table-column>
                <el-table-column prop="tel" label="电话号码"></el-table-column>
                <el-table-column prop="discountCode" label="优惠码"></el-table-column>
                <el-table-column  prop="ip" label="上次登录IP地址" ></el-table-column>
                <el-table-column fixed="right" label="操作" width="240">
                  <template slot-scope="scope">
                    <div class="table-btn-box">
                      <div class="ct-btn view" @click="handleViewClick(scope.row)">
                        查看
                      </div>
                      <div class="ct-btn view" @click="handleEditClick(scope.row)">
                        编辑
                      </div>
                      <div class="ct-btn inactive" v-if="scope.row.userType == 1" @click="handleTypeChangeClick(scope.row,0)">停用</div>
                      <div class="ct-btn active" v-else @click="handleTypeChangeClick(scope.row,1)">启用</div>
                      <div class="ct-btn del" @click="handleDeleteClick(scope.row)">
                        删除
                      </div>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="table-container-footer">
             <el-pagination
                layout="total, prev, pager, next, sizes, jumper"
                :page-sizes="[10, 50, 100, 500]"
                :page-size="pageSize"
                :total="tableTotal"
                :current-page="pageNo"
                @current-change="pageChange"
                @size-change="sizeChange"
              >
              </el-pagination>
            </div>
            <el-dialog
              title="添加用户"
              width="40%"
              :visible.sync="addDialogVisible"
              :before-close="handleAddClose"
            >
              <el-row>
                <el-col :span="8" class="add-col">
                  <div class="add-label">
                    <div class="required-label">*</div>
                    账号
                  </div>
                  <div class="add-input-box">
                    <el-input
                      v-model="userName"
                      placeholder="请输入账号"
                      class="form-control"
                    ></el-input>
                  </div>
                </el-col>
                <el-col :span="8" class="add-col">
                  <div class="add-label">
                    <div class="required-label">*</div>
                    密码
                  </div>
                  <div class="add-input-box">
                    <el-input
                      v-model="password"
                      type="password"
                      placeholder="请输入您的密码"
                      class="form-control"
                    ></el-input>
                  </div>
                </el-col>
                <el-col :span="8" class="add-col">
                  <div class="add-label">
                    <div class="required-label">*</div>
                    真实姓名
                  </div>
                  <div class="add-input-box">
                    <el-input
                      v-model="realName"
                      placeholder="请输入真实姓名"
                      class="form-control"
                    ></el-input>
                  </div>
                </el-col>
                <el-col :span="8" class="add-col">
                  <div class="add-label">
                    <div class="required-label">*</div>
                    电话号码
                  </div>
                  <div class="add-input-box">
                    <el-input
                      v-model="tel"
                      placeholder="请输入电话"
                      class="form-control"
                    ></el-input>
                  </div>
                </el-col>
                <el-col :span="8" class="add-col">
                  <div class="add-label"><div class="required-label">*</div>人员类型</div>
                  <div class="add-input-box">
                    <el-select class="form-control" v-model="adminType" 
                    placeholder="请选择人员类型"
                    element-loading-spinner="el-icon-loading">
                      <el-option
                        v-for="item in adminTypeList"
                        :key="item.key"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </div>
                </el-col>
                <el-col :span="8" class="add-col">
                  <div class="add-label"><div class="required-label">*</div>是否可用</div>
                  <div class="add-input-box">
                    <el-select class="form-control" v-model="userType" 
                    placeholder="请选择账号是否可用"
                    element-loading-spinner="el-icon-loading">
                      <el-option
                        v-for="item in userTypeList"
                        :key="item.key"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </div>
                </el-col>
                <el-col :span="8" class="add-col">
                  <div class="add-label">填报学生数量</div>
                  <div class="add-input-box">
                    <el-input
                      v-model="editStudentCount"
                      type="number"
                      placeholder="请输入填报学生数量"
                      class="form-control"
                    ></el-input>
                  </div>
                </el-col>
              </el-row>
              

              <span slot="footer" class="dialog-footer">
                <div class="btn-box">
                  <div class="btn-dialog-cancel" @click="addDialogVisible = false">关闭</div>
                  <div class="btn-dialog-confirm" @click="handleAddSaveClick()">保存</div>
                </div>
              </span>
            </el-dialog>
            <el-dialog
              title="用户信息"
              width="40%"
              :visible.sync="viewDialogVisible"
              :before-close="handleAddClose"
            >
              <el-row>
                <el-col :span="8" class="add-col">
                  <div class="add-label">
                    账号：
                  </div>
                  <div class="view-text-box">
                    {{viewUserName}}
                  </div>
                </el-col>
                <el-col :span="8" class="add-col">
                  <div class="add-label">
                    真实姓名：
                  </div>
                  <div class="view-text-box">
                    {{viewRealName}}
                  </div>
                </el-col>
                <el-col :span="8" class="add-col">
                  <div class="add-label">
                    电话号码：
                  </div>
                  <div class="view-text-box">
                    {{viewTel}}
                  </div>
                </el-col>
                <el-col :span="8" class="add-col">
                  <div class="add-label">人员类型：</div>
                  <div class="view-text-box">
                    <el-tag v-if="viewAdminType == 0" type="warning">管理员</el-tag>
                    <el-tag v-else-if="viewAdminType == 2" type="danger">超级管理员</el-tag>
                    <el-tag v-else type="info">非管理员</el-tag>
                  </div>
                </el-col>
                <el-col :span="8" class="add-col">
                  <div class="add-label">是否可用：</div>
                  <div class="view-text-box">
                    <el-tag v-if="viewUserType == 1" type="success">可用</el-tag>
                    <el-tag v-else type="danger">不可用</el-tag>
                  </div>
                </el-col>
                <el-col :span="8" class="add-col">
                  <div class="add-label">填报学生数量：</div>
                  <div class="view-text-box">
                    {{viewStudentCount}}
                  </div>
                </el-col>
                <el-col :span="8" class="add-col">
                  <div class="add-label">
                    优惠码：
                  </div>
                  <div class="view-text-box">
                    {{discountCode}}
                  </div>
                </el-col>
              </el-row>
              

              <span slot="footer" class="dialog-footer">
                <div class="btn-box">
                  <div class="btn-dialog-cancel" @click="viewDialogVisible = false">关闭</div>
                </div>
              </span>
            </el-dialog>
            <el-dialog
              title="更改用户信息"
              width="40%"
              :visible.sync="EditDialogVisible"
              :before-close="handleAddClose"
            >
              <el-row>
                <el-col :span="8" class="add-col">
                  <div class="add-label">
                    <div class="required-label">*</div>
                    账号
                  </div>
                  <div class="add-input-box">
                    <el-input
                      v-model="editUserName"
                      placeholder="请输入账号"
                      class="form-control"  :disabled="true"
                    ></el-input>
                  </div>
                </el-col>
                <el-col :span="8" class="add-col">
                  <div class="add-label">
                    密码
                  </div>
                  <div class="add-input-box">
                    <el-input
                      v-model="editPassword"
                      type="password"
                      placeholder="******"
                      class="form-control"
                    ></el-input>
                  </div>
                </el-col>
                <el-col :span="8" class="add-col">
                  <div class="add-label">
                    <div class="required-label">*</div>
                    真实姓名
                  </div>
                  <div class="add-input-box">
                    <el-input
                      v-model="editRealName"
                      placeholder="请输入真实姓名"
                      class="form-control"
                    ></el-input>
                  </div>
                </el-col>
                <el-col :span="8" class="add-col">
                  <div class="add-label">
                    <div class="required-label">*</div>
                    电话号码
                  </div>
                  <div class="add-input-box">
                    <el-input
                      v-model="editTel"
                      placeholder="请输入电话"
                      class="form-control"
                    ></el-input>
                  </div>
                </el-col>
                <el-col :span="8" class="add-col">
                  <div class="add-label"><div class="required-label">*</div>人员类型</div>
                  <div class="add-input-box">
                    <el-select class="form-control" v-model="editAdminType" 
                    placeholder="请选择人员类型"
                    element-loading-spinner="el-icon-loading">
                      <el-option
                        v-for="item in adminTypeList"
                        :key="item.key"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </div>
                </el-col>
                <el-col :span="8" class="add-col">
                  <div class="add-label"><div class="required-label">*</div>是否可用</div>
                  <div class="add-input-box">
                    <el-select class="form-control" v-model="editUserType" 
                    placeholder="请选择账号是否可用"
                    element-loading-spinner="el-icon-loading">
                      <el-option
                        v-for="item in userTypeList"
                        :key="item.key"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </div>
                </el-col>
                <el-col :span="8" class="add-col">
                  <div class="add-label">填报学生数量</div>
                  <div class="add-input-box">
                    <el-input
                      v-model="editStudentCount"
                      type="number"
                      placeholder="请输入填报学生数量"
                      class="form-control"
                    ></el-input>
                  </div>
                </el-col>
                 <el-col :span="8" class="add-col">
                  <div class="add-label">优惠码</div>
                  <div class="add-input-box">
                    <el-input
                      v-model="editDiscountCode"
                      maxlength="6"
                      type="text"
                    ></el-input>
                    <el-tooltip class="item" effect="dark" content="生成随机优惠码" placement="top">
                      <div class='btn' @click="handleDiscountCodeClick()"><i class="el-icon-refresh-left"></i></div>
                    </el-tooltip>
                  </div>
                  
                </el-col>
              </el-row>
              

              <span slot="footer" class="dialog-footer">
                <div class="btn-box">
                  <div class="btn-dialog-cancel" @click="EditDialogVisible = false">关闭</div>
                  <div class="btn-dialog-confirm" @click="handleEditSaveClick()">保存</div>
                </div>
              </span>
            </el-dialog>
          </div>
</template>

<script>
export default {
  name: "customerC",
  components: {},
  props: {
    msg: String,
  },
  data() {
    return {
      pageNo: 1,
      pageSize:10,
      tableTotal:0,
      addDialogVisible: false,
      viewDialogVisible: false,
      EditDialogVisible: false,
      tableLoading:false,
      //列表查询
      searchName:"",
      searchTel:"",
      searchRealName:"",
      searchAdminType:"",
      searchAdminTypeLoading:false,
      //添加页面
      userName:"",
      password:"",
      realName:"",
      tel:"",
      adminType:"1",
      userType:"1",
      studentCount:1,
      adminTypeList: [
        { key:"0",label:"管理员",value:"0"},
        { key:"1",label:"非管理员",value:"1"}
      ],
      userTypeList:[
        { key:"0",label:"停用",value:"0"},
        { key:"1",label:"可用",value:"1"}
      ],
      tableData: [],
      //查看页面
      viewAdminType:"",
      viewRealName:"",
      viewTel:"",
      viewStudentCount:"",
      viewUserType:"",
      viewUserName:"",
      discountCode:"",
      //编辑页面
      editId:"",
      editAdminType:"",
      editRealName:"",
      editTel:"",
      editPassword:"",
      editStudentCount:"",
      editUserType:"",
      editUserName:"",
      editDiscountCode:"",
    };
  },
  methods: {
    initSelectList(){
     
    },
    initTableData(){
      this.tableLoading = true;
      var data ={
          username:this.searchName,
          phone:this.searchTel,
          fullname:this.searchRealName,
          currentPage: this.pageNo,
          pageSize: this.pageSize
      };
      this.$http.post(this.$apis.getUserByPage,data).then(r=>{
        this.tableLoading = false;
        if(r.data && r.data.list){
          this.tableData = r.data.list.map(m=>{
            return {
              id:m.id,
              userName: m.username,
              tel: m.phone,
              adminType: m.adminType,
              agentCode: m.agentCode,
              fullname: m.fullname,
              ip: m.ip,
              password: m.password,
              region: m.region,
              studentCount: m.studentCount,
              userType: m.userType,
              discountCode: m.discountCode
            }
          });
          this.tableTotal = r.data.total;
        }
      });
    },
    initAddData(){
      this.userName="";
      this.password="";
      this.realName="";
      this.tel="";
      this.adminType="1";
      this.userType="1";
      this.studentCount=1;
    },
    handleAddClick(){
      this.initAddData();
      this.addDialogVisible=true;
    },
    handleSearchClick(){
      this.initTableData();
    },
    handleAddSaveClick(){
      if(!this.userName || this.userName === ""){
        this.$message.error("用户名不能为空");
        return;
      } 
      if(!this.password || this.password === ""){
         this.$message.error("密码不能为空");
        return;
      }
      if(!this.realName || this.realName === ""){
        this.$message.error("真实姓名不能为空");
        return;
      }
      if(!this.tel || this.tel === '' || !/^1\d{10}$/.test(this.tel)){
         this.$message.error("电话号码格式有误");
        return;
      }
      if(!this.adminType || this.adminType === ""){
        this.$message.error("人员类型不能为空");
        return;
      }
      if(!this.userType || this.userType === ""){
         this.$message.error("是否可用不能为空");
        return;
      }
      var data ={
        adminType: this.adminType,
        fullname: this.realName,
        password: this.password,
        phone: this.tel,
        userType: this.userType,
        username: this.userName,
      };
      this.$http.post(this.$apis.postUserAdd,data).then((res) => {
        if (res.code==200 && res.message==="SUCCESS") {
            this.$message.success("创建成功!");
            this.addDialogVisible=false;
        }
        else{
            this.$message.error("创建失败!原因:"+res.message);
        }
      });
    },
    handleTypeChangeClick(row,type) {
      var data ={ 
        id: row.id,
        userType: type,
      };
      this.$http.post(this.$apis.updateUserInfo,data).then((res)=>{
        if(res.code==200  && res.message==="SUCCESS"){
          this.$message.success( "设置"+ (type==0?"停用":"启用") + "成功!");
          this.initTableData();
        }else{
            this.$message.error("操作失败!原因:"+res.message);
        }
      });
    },
    handleDeleteClick(row){
      this.$confirm('是否确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.post(this.$apis.deleteUser+"?id="+row.id).then((res) => {
          if (res.code==200 && res.message==="SUCCESS") {
                this.$message({
                  type: 'success',
                  message: '删除成功!'
                });
                this.initTableData();
              }else{
                this.$message({
                  message: "删除失败!原因:"+res.message,
                  type: "error",
                });
              }
          });
        });
    },
    handleViewClick(row){
      this.viewDialogVisible = true;
      this.$http.post(this.$apis.getUserDetail+"?id="+row.id).then((res)=>{
        if(res.data){
          this.viewAdminType = res.data.adminType;
          this.viewRealName = res.data.fullname;
          this.viewTel = res.data.phone==""||res.data.phone==null ? "无" :res.data.phone;
          this.viewStudentCount = res.data.studentCount??0;
          this.viewUserType = res.data.userType;
          this.viewUserName = res.data.username;
          this.discountCode = res.data.discountCode
        }
      })
    },
    handleEditClick(row){
      this.EditDialogVisible = true;
      this.$http.post(this.$apis.getUserDetail+"?id="+row.id).then((res)=>{
        if(res.data){
          this.editId = res.data.id;
          this.editAdminType = res.data.adminType.toString();
          this.editRealName = res.data.fullname;
          this.editTel = res.data.phone;
          this.editStudentCount = res.data.studentCount??0;
          this.editUserType = res.data.userType.toString();
          this.editUserName = res.data.username;
          this.editDiscountCode = res.data.discountCode;
        }
      })  
    },
    handleEditSaveClick(){
      if(!this.editRealName || this.editRealName === ""){
        this.$message.error("真实姓名不能为空");
        return;
      }
      if(!this.editTel || this.editTel === '' || !/^1\d{10}$/.test(this.editTel)){
         this.$message.error("电话号码格式有误");
        return;
      }
      if(!this.editAdminType || this.editAdminType === ""){
        this.$message.error("人员类型不能为空");
        return;
      }
      if(!this.editUserType || this.editUserType === ""){
         this.$message.error("是否可用不能为空");
        return;
      }
      if(this.editDiscountCode && (this.editDiscountCode.length < 6 || !/^\d+(?=\.{0,1}\d+$|$)/.test(this.editDiscountCode))){
        this.$message.error("优惠码格式不正确!请输入六位数字或点击重新生成");
        return;
      }
      this.$http.post(this.$apis.checkDiscountCode+"?discountCode="+this.editDiscountCode).then((res)=>{
        if(res.code==200  || res.message==="SUCCESS"){
          if(!res.data){
            this.$message.error("优惠码已存在，请重新输入");
            return;
          }else{
             var data ={
                id: this.editId,
                adminType: this.editAdminType,
                fullname: this.editRealName,
                phone: this.editTel,
                userType: this.editUserType,
                studentCount: this.editStudentCount,
                discountCode: this.editDiscountCode
              };
              if(this.editPassword && this.editPassword != ""){
                data.password = this.editPassword;
              }
              this.$http.post(this.$apis.updateUserInfo,data).then((res)=>{
                  if(res.code==200  && res.message==="SUCCESS"){
                    this.EditDialogVisible = false;
                    this.$message.success( "修改成功!");
                    this.initTableData();
                  }else{
                      this.$message.error("操作失败!原因:"+res.message);
                  }
              });
          }
        }else{
            this.$message.error("操作失败!原因:"+res.message);
        }
      })
      
    },
    handleDiscountCodeClick(){
      var number="";
      for(var i=0;i<6;i++)
      {
        number += Math.floor(Math.random()*10);
      }
      this.editDiscountCode = number;
    },
    pageChange(val) {
      this.pageNo = val;
      this.initTableData();
    },
    sizeChange(val){
      this.pageSize = val;
      this.initTableData();
    },
    handleAddClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    }
  },
  mounted(){
    this.initSelectList();
    this.initTableData();
  }
}
</script>
<style lang="less" scoped>
/deep/ .el-table__body-wrapper {
    height: 90% !important;
}
.customer-c {
  margin: 20px;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  border-radius: 5px;
  border: 1px solid rgba(230, 230, 230, 100);

  .btn {
      line-height: 38px;
      width: 60px;
      border-radius: 4px;
      background-color: rgba(255, 69, 11, 100);
      text-align: center;
      color: #fff;
      margin-left:8px;
      cursor: pointer;
      font-size: 14px;
  }
  .top-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 10px;
    border-bottom: 1px solid rgba(230, 230, 230, 100);

    .top-title {
      color: rgba(16, 16, 16, 100);
      font-size: 20px;
      font-weight: 700;
      padding-left: 20px;
      position: relative;
    }
    .top-title::after {
      position: absolute;
      display: block;
      content: "";
      top: 50%;
      left: 5px;
      transform: translateY(-50%);
      height: 27px;
      width: 3px;
      background-color: #ff450b;
    }

    .btn-box {
      display: flex;
      align-items: center;
      .btn {
        line-height: 32px;
        width: 95px;
        border-radius: 4px;
        background-color: rgba(255, 69, 11, 100);
        text-align: center;
        color: #fff;
        margin-left: 15px;
        cursor: pointer;
        font-size: 14px;
      }
    }
  }

  .serrch-box {
    padding: 0 20px;
    display: flex;
    flex-wrap: wrap;

    .search-item {
      display: flex;
      align-items: center;
      width: 30%;
      padding: 15px 0;
      .label {
        color: rgba(100, 100, 100, 100);
        font-size: 14px;
        width: 80px;
        display: flex;
        .required-label {
          color: rgb(255, 0, 0);
        }
      }
      .s-box {
        width: calc(100% - 100px);
      }
    }
  }

  .btn-box {
    display: flex;
    align-items: center;
    .btn-dialog-cancel {
      line-height: 32px;
      width: 95px;
      border-radius: 4px;
      background-color: #fff;
      text-align: center;
      color: #000;
      margin-left: 15px;
      cursor: pointer;
      font-size: 14px;
      border: 1px solid #c9c9c9;
    }
    .btn-dialog-confirm {
      line-height: 32px;
      width: 95px;
      border-radius: 4px;
      background-color: rgba(255, 69, 11, 100);
      text-align: center;
      color: #fff;
      margin-left: 15px;
      cursor: pointer;
      font-size: 14px;
    }
  }
  .add-other {
    padding: 15px 0px;
    .add-input-box {
      display: flex;
      width: calc(100% - 100px);
    }
  }
  .add-service{
    padding: 15px 0px;
  }
  .add-col {
    padding: 15px 0px;
    display: flex;
    align-items: center;
    .add-label {
      display: flex;
      justify-content: flex-end;
      padding-right: 10px;
      width: 120px;
      .required-label {
        color: rgb(255, 0, 0);
      }
    }
    .add-input-box {
      display: flex;
      width: calc(100% - 100px);
    }
    .view-text-box{
      height: 40px;
      display: flex;
      align-items: center;
      width: calc(100% - 100px);
    }
  }
  .form-group{
      padding: 0px 20px;
    .form-item {
      display: flex;
        height: 55px;
      align-items: center;
      padding: 15px 0;
      .label {
        display: flex;
        justify-content: flex-end;
        color: rgba(100, 100, 100, 100);
        font-size: 14px;
        width: 70px;
        padding-right:10px;
      }
      .input-box {
          display: flex;
          width: calc(100% - 100px);
      }
      .required-label{
            color: rgb(255, 0, 0);
      }
      .action-link{
          font-size: 14px;
          cursor:pointer;
          color: #ff450b;
      }
    }
  }
  .form-control {
    width: 100%;
  }
  .form-radio-group {
    display: flex;
    align-items: center;
    height: 40px;
  }
  .table-container-body {
    padding: 0 20px;
    .table-body{
      width: 100%;
      border: 1px solid #e6e6e6;
    }
    .table-btn-box {
      display: flex;
      align-items: center;
      justify-content: space-around;
      .table-btn {
        cursor: pointer;
        width: 60px;
        line-height: 28px;
        border-radius: 14px;
        color: rgba(121, 160, 241, 100);
        font-size: 12px;
        text-align: center;
        border: 1px solid rgba(121, 160, 241, 100);
      }
      .ct-btn {
        cursor: pointer;
        width: 60px;
        line-height: 28px;
        border-radius: 14px;
        color: rgba(121, 160, 241, 100);
        font-size: 12px;
        text-align: center;
        border: 1px solid rgba(121, 160, 241, 100);
      }
      .ct-btn.inactive {
        color: rgba(255, 148, 75, 100);
        border: 1px solid rgba(255, 148, 75, 100);
      }
      .ct-btn.active{
        color: #14b959;
        border: 1px solid #14b959;
      }
    }
  }
  .table-container-footer{
    padding: 20px;
  }
  .custom-dialog-body{
    width: 100%;
    .form-group{
      padding:0 !important;
      .form-item{
        padding:0 0 15px 0 !important;
        height: 55px;
      }
    }
    .search-btn {
        line-height: 32px;
        width: 100%;
        border-radius: 4px;
        background-color: rgba(255, 69, 11, 100);
        text-align: center;
        color: #fff;
        cursor: pointer;
        font-size: 14px;
    }
    .table-container-body{
      padding:0 !important;
    }
    .table-container-footer{
      padding: 10px 0;
      border:solid #e6e6e6;
      border-width: 0 1px 1px 1px;
    }
  }
  .dialog-footer{
    display: flex;
    justify-content: flex-end;
    .confirm-btn{
      line-height: 32px;
      width: 60px;
      border-radius: 4px;
      background-color: rgba(255, 69, 11, 100);
      text-align: center;
      color: #fff;
      cursor: pointer;
      font-size: 14px;
    }
  }
}
</style>